// src/components/Admin.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar,
  Tabs,
  Tab,
  TextField,
  Typography,
  Box,
  Container,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Divider,
  FormControlLabel,
  Checkbox,
  Grid,
  Paper,
  Tooltip,
  Button,
  Snackbar,
  Alert,
  IconButton,
  Drawer,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  Map as MapIcon,
  Logout as LogoutIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
  SmartToy as RobotIcon, // Заменено на SmartToy
  EmojiEmotions as StickerIcon, // Заменено на EmojiEmotions
  Visibility as VisibilityIcon,
} from '@mui/icons-material'; 
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Создаём стилизованный Paper для консистентного оформления секций
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
}));

// Компонент TabPanel для отображения содержимого вкладок
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

// === Обратите внимание: мы добавляем новую вкладку "Управление картами" ===
// Список навигационных пунктов (исключая "Карта" и "Выйти")
const navItems = [
  { label: 'Пользователи', icon: <PeopleIcon />, index: 0 },
  { label: 'Бот', icon: <RobotIcon />, index: 1 },
  { label: 'Стикеры', icon: <StickerIcon />, index: 2 },
  { label: 'Пользователи бота', icon: <VisibilityIcon />, index: 3 },
  { label: 'Настройки', icon: <SettingsIcon />, index: 4 },
  // === НОВЫЙ КОД ДЛЯ КАРТ ===
  { label: 'Управление картами', icon: <MapIcon />, index: 5 },
  { label: 'Управление маршрутами', icon: <MapIcon/>, index: 6 },
];

function Admin() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tabIndex, setTabIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // =========================
  //    Состояния (старые)
  // =========================
  // Состояния для Управления Пользователями
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [deleteUsername, setDeleteUsername] = useState('');
  const [users, setUsers] = useState([]);

  // Состояния для Управления Ботом
  const [botName, setBotName] = useState('');
  const [botLogin, setBotLogin] = useState('');
  const [botPassword, setBotPassword] = useState('');
  const [selectedUserForPassword, setSelectedUserForPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [selectedUserToDelete, setSelectedUserToDelete] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isServerUpdating, setIsServerUpdating] = useState(false); // Новое состояние
  const [confirmOpen, setConfirmOpen] = useState(false);
  // Состояния для Управления Стикерами
  const [selectedUserForSticker, setSelectedUserForSticker] = useState('');
  const [stickerText, setStickerText] = useState('');

  // Состояния для Просмотра Пользователей
  const [allUsers, setAllUsers] = useState([]);

  // Состояния для Уведомлений
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const [routeSets, setRouteSets] = useState([]);
const [routeName, setRouteName] = useState('');
const [routeSheetLink, setRouteSheetLink] = useState('');
const [routePoints, setRoutePoints] = useState(20);

  // =========================
  //   Состояния (Новые) для вкладки "Управление картами"
  // =========================
  const [mapName, setMapName] = useState('');
  const [googleSheetLink, setGoogleSheetLink] = useState('');
  const [maps, setMaps] = useState([]); // список карт из Mongo

  const navigate = useNavigate();

  // =========================
  //   Функции (старые)
  // =========================

  // Получение списка пользователей
  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://mapyg.ru/server/users', {
        withCredentials: true,
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Ошибка при получении пользователей:', error);
      setError('Не удалось загрузить пользователей.');
    }
  };

  // Получение всех пользователей (бот)
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(
        `https://mapyg.ru/bot/users?timestamp=${new Date().getTime()}`
      );
      setAllUsers(response.data);
    } catch (error) {
      console.error('Ошибка при получении всех пользователей:', error);
      setError('Не удалось загрузить пользователей.');
    }
  };

  // При загрузке компонента
  useEffect(() => {
    fetchUsers();
    fetchAllUsers();

    // === НОВЫЙ КОД ДЛЯ КАРТ ===
    fetchMaps(); // подгружаем список карт при монтировании
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Обработчики вкладок
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    if (isMobile) {
      setDrawerOpen(false); 
    }
  };

  const handleUpdateTasks = async () => {
    // Проверяем, выполняется ли уже обновление на сервере
    if (isServerUpdating) {
      setError('Обновление уже выполняется. Пожалуйста, подождите.');
      return;
    }

    setConfirmOpen(false); // Закрываем диалог подтверждения
    setUpdateLoading(true);
    setError(null);
    setMessage(null);

    try {
      const response = await axios.get('https://mapyg.ru/api/update-markers', {
        withCredentials: true,
      });
      setMessage(response.data.message || 'Обновление задач выполнено успешно.');
      // После успешного обновления обновляем состояние
      setIsServerUpdating(false);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          setError('Обновление уже выполняется.');
        } else {
          setError(error.response.data.message || 'Ошибка при обновлении задач.');
        }
      } else {
        setError('Ошибка при обновлении задач.');
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  // Управление Пользователями
  const handleCreateUser = async () => {
    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      await axios.post('https://mapyg.ru/server/register', {
        username,
        password,
        isAdmin,
      });
      setMessage('Пользователь успешно создан!');
      setUsername('');
      setPassword('');
      setIsAdmin(false);
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при создании пользователя', error);
      if (error.response && error.response.status === 400) {
        setError('Пользователь уже существует.');
      } else {
        setError('Ошибка при создании пользователя. Пожалуйста, попробуйте еще раз.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    if (deleteUsername === 'Admin') {
      setError('Пользователь "Admin" не может быть удален.');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      await axios.delete(`https://mapyg.ru/server/users/${deleteUsername}`);
      setMessage(`Пользователь ${deleteUsername} успешно удален.`);
      setDeleteUsername('');
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при удалении пользователя', error);
      setError('Ошибка при удалении пользователя. Пожалуйста, попробуйте снова.');
    } finally {
      setLoading(false);
    }
  };

  // Управление Ботом
  const handleBotRegister = async () => {
    if (!botName || !botLogin || !botPassword) {
      setError('Пожалуйста, заполните все поля для регистрации.');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      await axios.post(
        `https://mapyg.ru/bot/register?timestamp=${new Date().getTime()}`,
        {
          name: botName,
          login: botLogin,
          password: botPassword,
        }
      );
      setMessage('Пользователь успешно зарегистрирован.');
      setBotName('');
      setBotLogin('');
      setBotPassword('');
      fetchAllUsers();
    } catch (error) {
      console.error('Ошибка при регистрации пользователя', error);
      setError('Ошибка при регистрации пользователя. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (!selectedUserForPassword || !newPassword) {
      setError('Пожалуйста, выберите пользователя и введите новый пароль.');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      await axios.post('https://mapyg.ru/bot/admin/change-password', {
        login: selectedUserForPassword,
        newPassword,
      });
      setMessage(`Пароль пользователя ${selectedUserForPassword} успешно изменен.`);
      setNewPassword('');
    } catch (error) {
      console.error('Ошибка при смене пароля', error);
      setError('Ошибка при смене пароля. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBotUser = async () => {
    if (!selectedUserToDelete) {
      setError('Пожалуйста, выберите пользователя для удаления.');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      await axios.delete(
        `https://mapyg.ru/bot/user/${selectedUserToDelete}?timestamp=${new Date().getTime()}`,
        {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0',
          },
        }
      );
      setMessage(`Пользователь ${selectedUserToDelete} успешно удален.`);
      setSelectedUserToDelete('');
      fetchAllUsers();
    } catch (error) {
      console.error('Ошибка при удалении пользователя', error);
      if (error.response) {
        console.error('Ответ сервера:', error.response.data);
        setError(`Ошибка: ${error.response.data.message}`);
      } else {
        setError('Ошибка при удалении пользователя. Пожалуйста, попробуйте еще раз.');
      }
    } finally {
      setLoading(false);
    }
  };

  const checkServerUpdating = async () => {
    try {
      const response = await axios.get('https://mapyg.ru/api/is-updating', {
        withCredentials: true,
      });
      setIsServerUpdating(response.data.isUpdating);
    } catch (error) {
      console.error('Ошибка при проверке состояния обновления:', error);
      // В случае ошибки можно считать, что обновление не выполняется
      setIsServerUpdating(false);
    }
  };

  useEffect(() => {
    // Проверяем состояние обновления при монтировании
    checkServerUpdating();

    // Устанавливаем интервал для периодической проверки
    const interval = setInterval(() => {
      checkServerUpdating();
    }, 30000); // Каждые 30 секунд

    // Очищаем интервал при размонтировании
    return () => clearInterval(interval);
  }, []);


  // Управление Стикерами
  const handleAddSticker = async () => {
    if (!selectedUserForSticker || !stickerText) {
      setError('Пожалуйста, выберите пользователя и введите текст стикера.');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      await axios.post('https://mapyg.ru/bot/admin/add-sticker', {
        login: selectedUserForSticker,
        text: stickerText,
      });
      setMessage('Стикер успешно добавлен.');
      setStickerText('');
      fetchAllUsers();
    } catch (error) {
      console.error('Ошибка при добавлении стикера', error);
      setError('Ошибка при добавлении стикера. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSticker = async (stickerId) => {
    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      await axios.delete(`https://mapyg.ru/bot/admin/delete-sticker/${stickerId}`);
      setMessage('Стикер успешно удален.');
      fetchAllUsers();
    } catch (error) {
      console.error('Ошибка при удалении стикера', error);
      setError('Ошибка при удалении стикера. Пожалуйста, попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  // Навигация
  const handleLogout = async () => {
    try {
      await axios.post('https://mapyg.ru/server/logout', {}, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      console.error('Ошибка выхода:', error);
      setError('Ошибка выхода. Пожалуйста, попробуйте снова.');
    }
  };

  const handleGoToMap = () => {
    // Эта "Карта" - старая, не связана с управлением картами
    navigate('/map');
  };

  // =========================
  //   НОВЫЙ КОД ДЛЯ КАРТ
  // =========================

  // 1) Получение списка карт
  const fetchMaps = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://mapyg.ru/api/maps', {
        withCredentials: true,
      });
      setMaps(response.data);
    } catch (err) {
      console.error('Ошибка при получении карт:', err);
      setError('Не удалось загрузить список карт.');
    } finally {
      setLoading(false);
    }
  };

  // 2) Создание карты
  const handleCreateMap = async () => {
    if (!mapName || !googleSheetLink) {
      setError('Введите название карты и ссылку на Google Sheets.');
      return;
    }
    try {
      setLoading(true);
      setError(null);
      setMessage(null);

      await axios.post(
        'https://mapyg.ru/api/maps',
        {
          name: mapName,
          sheetLink: googleSheetLink,
        },
        { withCredentials: true }
      );

      setMessage('Карта успешно создана!');
      setMapName('');
      setGoogleSheetLink('');
      // Обновляем список
      fetchMaps();
    } catch (err) {
      console.error('Ошибка при создании карты:', err);
      setError('Не удалось создать карту. Попробуйте снова.');
    } finally {
      setLoading(false);
    }
  };

  // 3) Удаление карты
  const handleDeleteMap = async (mapId) => {
    try {
      setLoading(true);
      setError(null);
      setMessage(null);

      await axios.delete(`https://mapyg.ru/api/maps/${mapId}`, {
        withCredentials: true,
      });

      setMessage('Карта успешно удалена.');
      // Обновим список
      fetchMaps();
    } catch (err) {
      console.error('Ошибка при удалении карты:', err);
      setError('Не удалось удалить карту.');
    } finally {
      setLoading(false);
    }
  };

  // Функции генерации карт 

  // 3) Функция загрузки
async function fetchRouteSets(){
    try {
      setLoading(true);
      const resp = await axios.get('https://mapyg.ru/api/route-sets');
      setRouteSets(resp.data);
    } catch(err){
      setError('Ошибка при получении маршрутов');
    } finally {
      setLoading(false);
    }
  }
  
  // 4) Создание
  async function handleCreateRouteSet(){
    if(!routeName || !routeSheetLink || !routePoints){
      setError('Заполните все поля');
      return;
    }
    setLoading(true);
    setError(null);
    setMessage(null);
    try {
      const body = {
        name: routeName,
        sheetLink: routeSheetLink,
        pointsPerRoute: Number(routePoints),
      };
      const resp = await axios.post('https://mapyg.ru/api/route-sets', body);
      setMessage('Набор маршрутов успешно создан');
      setRouteSets(resp.data);
      setRouteName('');
      setRouteSheetLink('');
      setRoutePoints(20);
    } catch(err){
      setError('Ошибка при создании набора маршрутов');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }
  
  // 5) Кнопки скачивания
  function handleDownloadCsv(id){
    window.open(`https://mapyg.ru/api/route-sets/${id}/download-csv`, '_blank');
  }
  function handleDownloadXlsx(id){
    window.open(`https://mapyg.ru/api/route-sets/${id}/download-xlsx`, '_blank');
  }
  
  // 6) "Посмотреть маршруты" -> какую-то страницу
  function handleOpenRoutesMap(id){
    // Например: /route-sets/:id -> публичный просмотр
    window.open(`/routes-map/${id}`, '_blank');
  }
  
  // 7) Вызываем fetchRouteSets() при монтировании
  useEffect(()=>{
    fetchRouteSets();
  }, []);

  // --------------------------
  //   Мобильное меню
  // --------------------------
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // Компонент списка для Drawer (мобильное меню)
  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navItems.map((item) => (
          <ListItem button key={item.label} onClick={() => setTabIndex(item.index)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
        <Divider />
        <ListItem button onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Выйти" />
        </ListItem>
      </List>
    </Box>
  );

  // --------------------------
  //   Рендер
  // --------------------------
  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Вкладки и Верхнее Меню */}
      <Box sx={{ width: '100%' }}>
        <AppBar position="static" color="default">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Логотип или название */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, paddingLeft: 2 }}>
              MapYG Админ Панель
            </Typography>
            {/* Кнопка "Карта" (Старая) */}
            <Tooltip title="Перейти на карту">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<MapIcon />}
                onClick={handleGoToMap}
                sx={{
                  marginRight: 2,
                  borderRadius: 2,
                  boxShadow: 1,
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: 3,
                  },
                }}
              >
                Карта
              </Button>
            </Tooltip>
            {/* Кнопка "Выйти" */}
            {!isMobile && (
              <Tooltip title="Выйти из аккаунта">
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<LogoutIcon />}
                  onClick={handleLogout}
                  sx={{
                    borderRadius: 2,
                    boxShadow: 1,
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: 3,
                    },
                  }}
                >
                  Выйти
                </Button>
              </Tooltip>
            )}
            {/* Мобильное меню */}
            {isMobile ? (
              <>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                >
                  {drawerList}
                </Drawer>
              </>
            ) : null}
          </Box>
          {/* Десктопное меню вкладок */}
          {!isMobile && (
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="admin tabs"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              {navItems.map((item) => (
                <Tab key={item.label} label={item.label} />
              ))}
            </Tabs>
          )}
        </AppBar>

        {/* Вкладки */}
        {navItems.map((item) => (
          <TabPanel key={item.label} value={tabIndex} index={item.index}>
            {/* Вкладка: {item.label} */}

            {/* ========================== */}
            {/* --- СТАРЫЕ ВКЛАДКИ ---  */}
            {/* ========================== */}

            {/* 1. Пользователи */}
            {item.label === 'Пользователи' && (
              <>
                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Создать Пользователя
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        fullWidth
                        label="Имя пользователя"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Введите имя"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        fullWidth
                        type="password"
                        label="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Введите пароль"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isAdmin}
                            onChange={(e) => setIsAdmin(e.target.checked)}
                            color="primary"
                          />
                        }
                        label="Админ"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip title="Создать нового пользователя">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCreateUser}
                          disabled={loading}
                          fullWidth
                          sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                              boxShadow: 6,
                            },
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : 'Создать пользователя'}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledPaper>

                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Удалить Пользователя
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={8}>
                      <FormControl fullWidth>
                        <InputLabel id="delete-user-label">Пользователи</InputLabel>
                        <Select
                          labelId="delete-user-label"
                          value={deleteUsername}
                          label="Пользователи"
                          onChange={(e) => setDeleteUsername(e.target.value)}
                        >
                          {users.length > 0 ? (
                            users.map((user) => (
                              <MenuItem key={user.username} value={user.username}>
                                {user.username} {user.isAdmin ? '(Админ)' : ''}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>Нет доступных пользователей</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Tooltip title="Удалить выбранного пользователя">
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handleDeleteUser}
                          disabled={loading || !deleteUsername}
                          fullWidth
                          sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                              boxShadow: 6,
                            },
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : 'Удалить'}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledPaper>
              </>
            )}

            {/* 2. Бот */}
            {item.label === 'Бот' && (
              <>
                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Регистрация Пользователя Бота
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        fullWidth
                        label="Имя пользователя"
                        value={botName}
                        onChange={(e) => setBotName(e.target.value)}
                        placeholder="Введите имя"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        fullWidth
                        label="Логин"
                        value={botLogin}
                        onChange={(e) => setBotLogin(e.target.value)}
                        placeholder="Введите логин"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        fullWidth
                        type="password"
                        label="Пароль"
                        value={botPassword}
                        onChange={(e) => setBotPassword(e.target.value)}
                        placeholder="Введите пароль"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip title="Зарегистрировать нового бота">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleBotRegister}
                          disabled={loading}
                          fullWidth
                          sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                              boxShadow: 6,
                            },
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : 'Зарегистрировать'}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledPaper>

                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Смена Пароля Пользователя Бота
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="change-password-user-label">Пользователи</InputLabel>
                        <Select
                          labelId="change-password-user-label"
                          value={selectedUserForPassword}
                          label="Пользователи"
                          onChange={(e) => setSelectedUserForPassword(e.target.value)}
                        >
                          {allUsers.length > 0 ? (
                            allUsers.map((user) => (
                              <MenuItem key={user.login} value={user.login}>
                                {user.name} ({user.login})
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>Нет доступных пользователей</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        type="password"
                        label="Новый пароль"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Введите новый пароль"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip title="Изменить пароль выбранного пользователя">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleChangePassword}
                          disabled={loading || !selectedUserForPassword || !newPassword}
                          fullWidth
                          sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                              boxShadow: 6,
                            },
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : 'Изменить пароль'}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledPaper>

                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Удаление Пользователя Бота
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={8}>
                      <FormControl fullWidth>
                        <InputLabel id="delete-bot-user-label">Пользователи</InputLabel>
                        <Select
                          labelId="delete-bot-user-label"
                          value={selectedUserToDelete}
                          label="Пользователи"
                          onChange={(e) => setSelectedUserToDelete(e.target.value)}
                        >
                          {allUsers.length > 0 ? (
                            allUsers.map((user) => (
                              <MenuItem key={user.login} value={user.login}>
                                {user.name} ({user.login})
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>Нет доступных пользователей</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Tooltip title="Удалить выбранного пользователя">
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handleDeleteBotUser}
                          disabled={loading || !selectedUserToDelete}
                          fullWidth
                          sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                              boxShadow: 6,
                            },
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : 'Удалить'}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledPaper>
              </>
            )}

            {/* 3. Стикеры */}
            {item.label === 'Стикеры' && (
              <>
                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Добавить Стикер Пользователю
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel id="sticker-user-label">Пользователь</InputLabel>
                        <Select
                          labelId="sticker-user-label"
                          value={selectedUserForSticker}
                          label="Пользователь"
                          onChange={(e) => setSelectedUserForSticker(e.target.value)}
                        >
                          {allUsers.length > 0 ? (
                            allUsers.map((user) => (
                              <MenuItem key={user.login} value={user.login}>
                                {user.name} ({user.login})
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>Нет доступных пользователей</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Текст стикера"
                        value={stickerText}
                        onChange={(e) => setStickerText(e.target.value)}
                        placeholder="Введите текст"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Tooltip title="Добавить стикер">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddSticker}
                          disabled={loading || !selectedUserForSticker || !stickerText}
                          fullWidth
                          sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                            height: '100%',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                              boxShadow: 6,
                            },
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : 'Добавить'}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledPaper>

                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Стикеры Пользователя
                  </Typography>
                  {selectedUserForSticker ? (
                    allUsers.find((user) => user.login === selectedUserForSticker)?.stickers &&
                    allUsers.find((user) => user.login === selectedUserForSticker).stickers.length > 0 ? (
                      <List>
                        {allUsers
                          .find((user) => user.login === selectedUserForSticker)
                          .stickers.map((sticker) => (
                            <ListItem
                              key={sticker._id}
                              secondaryAction={
                                <Tooltip title="Удалить стикер">
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDeleteSticker(sticker._id)}
                                    disabled={loading}
                                    sx={{
                                      borderRadius: 2,
                                      boxShadow: 1,
                                      transition: 'transform 0.2s, box-shadow 0.2s',
                                      '&:hover': {
                                        transform: 'scale(1.02)',
                                        boxShadow: 3,
                                      },
                                    }}
                                  >
                                    Удалить
                                  </Button>
                                </Tooltip>
                              }
                            >
                              <ListItemText primary={sticker.text} />
                            </ListItem>
                          ))}
                      </List>
                    ) : (
                      <Typography>У пользователя нет стикеров.</Typography>
                    )
                  ) : (
                    <Typography>Выберите пользователя, чтобы увидеть стикеры.</Typography>
                  )}
                </StyledPaper>
              </>
            )}

            {/* 4. Пользователи бота */}
            {item.label === 'Пользователи бота' && (
              <StyledPaper>
                <Typography variant="h6" gutterBottom>
                  Список Пользователей
                </Typography>
                {allUsers.length > 0 ? (
                  <List>
                    {allUsers.map((user) => (
                      <React.Fragment key={user.login}>
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            primary={`${user.name} (${user.login})`}
                            secondary={
                              user.stickers && user.stickers.length > 0
                                ? `Стикеры: ${user.stickers.map((s) => s.text).join(', ')}`
                                : 'Стикеров нет'
                            }
                          />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    ))}
                  </List>
                ) : (
                  <Typography>Нет доступных пользователей.</Typography>
                )}
              </StyledPaper>
            )}

            {/* 5. Настройки */}
            {item.label === 'Настройки' && (
              <StyledPaper>
                <Typography variant="h6" gutterBottom>
                  Настройки
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Tooltip title="Перейти на карту">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGoToMap}
                        fullWidth
                        sx={{
                          borderRadius: 2,
                          boxShadow: 3,
                          height: '100%',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 6,
                          },
                        }}
                      >
                        Карта
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Tooltip title="Выйти из аккаунта">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleLogout}
                        fullWidth
                        sx={{
                          borderRadius: 2,
                          boxShadow: 3,
                          height: '100%',
                          transition: 'transform 0.2s, box-shadow 0.2s',
                          '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 6,
                          },
                        }}
                      >
                        Выйти
                      </Button>
                    </Tooltip>
                  </Grid>
                   {/* Новая кнопка для обновления задач */}
                    <Grid item xs={12}>
                      <Tooltip title="Обновить все задачи вручную">
                        <span style={{ width: '100%' }}>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => setConfirmOpen(true)}
                            disabled={updateLoading || isServerUpdating}
                            fullWidth
                            sx={{
                              borderRadius: 2,
                              boxShadow: 3,
                              transition: 'transform 0.2s, box-shadow 0.2s',
                              '&:hover': {
                                transform: 'scale(1.02)',
                                boxShadow: 6,
                              },
                            }}
                          >
                            {updateLoading ? <CircularProgress size={24} /> : 'Обновить все задачи'}
                          </Button>
                        </span>
                      </Tooltip>
                      {/* Отображение сообщения, если обновление выполняется на сервере */}
                      {isServerUpdating && (
                        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
                          Обновление уже выполняется.
                        </Typography>
                      )}
                    </Grid>
                </Grid>
              </StyledPaper>
            )}

            {/* ========================== */}
            {/* --- НОВАЯ ВКЛАДКА "Управление картами" --- */}
            {/* ========================== */}
            {item.label === 'Управление картами' && (
              <>
                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Создание карты
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Название карты"
                        value={mapName}
                        onChange={(e) => setMapName(e.target.value)}
                        placeholder="Введите название карты"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Ссылка на Google Sheets"
                        value={googleSheetLink}
                        onChange={(e) => setGoogleSheetLink(e.target.value)}
                        placeholder="Вставьте ссылку на Google Sheets"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip title="Создать карту на основе Google Sheets">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCreateMap}
                          disabled={loading}
                          fullWidth
                          sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                              boxShadow: 6,
                            },
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : 'Создать карту'}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledPaper>

                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Список созданных карт
                  </Typography>
                  {maps.length === 0 ? (
                    <Typography>Пока нет созданных карт.</Typography>
                  ) : (
                    <Box sx={{ overflowX: 'auto' }}>
                      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                          <tr style={{ backgroundColor: '#f0f0f0' }}>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>Название</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>Google Sheets</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>Ссылка на страницу</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>Дата создания</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>Действия</th>
                          </tr>
                        </thead>
                        <tbody>
                          {maps.map((m) => (
                            <tr key={m._id}>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                                {m.name}
                              </td>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                                <a
                                  href={m.sheetLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Открыть таблицу
                                </a>
                              </td>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                                {/* Публичная страница: /maps/:mapId */}
                                <a
                                  href={`/maps/${m._id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  /maps/{m._id}
                                </a>
                              </td>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                                {new Date(m.createdAt).toLocaleString('ru-RU', {
                                  timeZone: 'Europe/Moscow',
                                })}
                              </td>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                                <Tooltip title="Удалить карту">
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDeleteMap(m._id)}
                                    disabled={loading}
                                  >
                                    Удалить
                                  </Button>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                  )}
                </StyledPaper>
              </>
            )}
            {item.label==='Управление маршрутами' && (
  <>
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Создать набор маршрутов
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            required fullWidth
            label="Название набора"
            value={routeName}
            onChange={(e)=> setRouteName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required fullWidth
            label="Ссылка на Google Sheets"
            value={routeSheetLink}
            onChange={(e)=> setRouteSheetLink(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required fullWidth
            type="number"
            label="Кол-во точек в маршруте"
            value={routePoints}
            onChange={(e)=> setRoutePoints(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateRouteSet}
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24}/> : 'Создать'}
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>

    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Список наборов маршрутов
      </Typography>
      {routeSets.length === 0 ? (
        <Typography>Пока нет наборов</Typography>
      ) : (
        <table style={{width:'100%', borderCollapse:'collapse'}}>
          <thead>
            <tr>
              <th style={{borderBottom:'1px solid #ccc'}}>Название</th>
              <th style={{borderBottom:'1px solid #ccc'}}>Дата создания</th>
              <th style={{borderBottom:'1px solid #ccc'}}>Действия</th>
            </tr>
          </thead>
          <tbody>
            {routeSets.map(rs=>(
              <tr key={rs._id}>
                <td>{rs.name}</td>
                <td>{new Date(rs.createdAt).toLocaleString('ru-RU')}</td>
                <td>
                  <Button onClick={()=> handleDownloadXlsx(rs._id)}>Excel</Button>
                  <Button onClick={()=> handleOpenRoutesMap(rs._id)}>Маршруты</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </StyledPaper>
  </>
)}

          </TabPanel>
        ))}
      </Box>

      {/* Уведомления */}
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={() => setMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setMessage(null)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
      {/* Диалог подтверждения */}
      <Dialog
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle id="confirm-dialog-title">Подтвердите действие</DialogTitle>
            <DialogContent>
              <DialogContentText id="confirm-dialog-description">
                Вы уверены, что хотите обновить все задачи? Это может занять некоторое время.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmOpen(false)} color="primary">
                Отмена
              </Button>
              <Button onClick={handleUpdateTasks} color="primary" autoFocus>
                Подтвердить
              </Button>
            </DialogActions>
          </Dialog>
    </Container>
  );
}

export default Admin;
